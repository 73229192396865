<template>
  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation" style="
      padding-top: 15px;
      padding-bottom: 15px;">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <img src="https://cms.icts.hkbu.edu.hk/uploads/icts_logo_cb3ba3c817.png" alt=""
          style="max-height: 40px !important" />
      </a>

      <!-- <a class="navbar-item" href="/">
        <img src="/images/logo31.png" alt="" width="70" height="70" />
        <img src="/images/logo32.png" alt="" width="90" height="70" />
      </a> -->

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"
        @click="showNavMenu = !showNavMenu" :class="{ 'is-active': showNavMenu }">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': showNavMenu }" :key="path">
      <div class="navbar-start">
        <router-link class="navbar-item" to="/news-events/0">News &amp; Events</router-link>

        <!-- About ICTS -->
        <div class="navbar-item has-dropdown is-hoverable" :key="path">
          <a class="navbar-link" @click="{}"> About ICTS </a>

          <div class="navbar-dropdown">
            <router-link class="navbar-item" to="/mission">Mission and History</router-link>
            <router-link class="navbar-item" to="/donar">Donor Recognition</router-link>
            <router-link class="navbar-item" to="/message">Message from the Director</router-link>
          </div>
        </div>

        <!-- People -->
        <div class="navbar-item has-dropdown is-hoverable" :key="path">
          <a class="navbar-link" @click="{}"> People </a>

          <div class="navbar-dropdown">
            <router-link class="navbar-item" to="/member/core">Core Members</router-link>
            <router-link class="navbar-item" to="/associate">Associate Members</router-link>
            <router-link class="navbar-item" to="/member/advisory">Advisory Committee</router-link>
            <router-link class="navbar-item" to="/member/manage">Management Committee</router-link>
            <router-link class="navbar-item" to="/constituent">Constituent Research Centers</router-link>
            <router-link class="navbar-item" to="/become">Become a Member</router-link>
          </div>
        </div>

        <!-- Visit -->
        <div class="navbar-item has-dropdown is-hoverable" :key="path">
          <a class="navbar-link" @click="{}"> Visit </a>

          <div class="navbar-dropdown">
            <!-- <router-link class="navbar-item" to="/visit-icts">Visit ICTS</router-link> -->
            <router-link class="navbar-item" to="/getting-involved">Getting Involved</router-link>
          </div>
        </div>

        <router-link class="navbar-item" to="/research/theme0">Research</router-link>
        <router-link class="navbar-item" to="/ghmua">GHMUA-ICTS</router-link>
        <router-link class="navbar-item" to="/statphys28">StatPhysHK</router-link>
        <router-link class="navbar-item" to="/contact-us">Contact Us</router-link>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <!-- <a class="button is-primary">
              <strong>Sign up</strong>
            </a>
            <a class="button is-light"> Log in </a> -->
            <a href="https://www.hkbu.edu.hk/eng/main/index.jsp"><img 
                src="https://cms.icts.hkbu.edu.hk/uploads/hkbu_logo_2f52c9d1b2.png" alt=""
                style="max-height: 40px !important" /></a>
            <div class="is-hidden-tablet" style="height: 100px"></div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "SiteNav",
  setup() {
    const route = useRoute();
    const path = ref("");
    const showNavMenu = ref(false);

    onMounted(async () => {
      document.body.style.padding = "100px 0px 0px 0px";
    });
    watch(
      () => route.path,
      () => {
        path.value = route.path;
        showNavMenu.value = false;
      }
    );

    return {
      showNavMenu,
      path,
    };
  },
};
</script>
