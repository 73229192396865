<template>
  <div class="home">
    <section class="hero is-warning">
      <div class="hero-body">
        <p class="title">Institute of Computational and Theoretical Studies</p>
        <p class="subtitle">Faculty of Science, Hong Kong Baptist University</p>
      </div>
    </section>

    <section class="section" v-if="upcomings.length > 0">
      <div class="container">
        <div class="title">Upcoming Events</div>
        <hr />
        <EventCard
          textHeight="200px"
          :event="event"
          isUpcoming="true"
          v-for="(event, i) in upcomings"
          :key="i"
        />
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="title">Latest News</div>
        <hr />
        <EventCard
          textHeight="200px"
          :event="event"
          isUpcoming="false"
          v-for="(event, i) in pasts"
          :key="i"
        />
      </div>
    </section>
    <LinkTiles />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { ref, onMounted } from "vue";
import LinkTiles from "@/components/LinkTiles.vue";
import EventCard from "@/components/EventCard.vue";

export default {
  name: "HomeView",
  components: {
    LinkTiles,
    EventCard,
  },
  setup() {
    const upcomings = ref([]);
    const pasts = ref([]);

    onMounted(async () => {
      var response = await fetch("https://cms.icts.hkbu.edu.hk/api/events?pagination[pageSize]=3&sort[0]=date:desc");
      if (response.ok) {
        var temp = await response.json();
        temp = temp.data;
        pasts.value = temp;
        // upcomings.value = temp.slice(0, 2);
        // pasts.value = temp.slice(-3).reverse();
      } else {
        alert(response.status + " " + response.statusCode);
      }
    });

    return {
      upcomings,
      pasts,
    };
  },
};
</script>