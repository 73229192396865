<template>
  <div class="tile">
    <div class="tile is-parent is-8">
      <article class="tile is-child notification is-link is-light">
        <p class="subtitle">Constituent Research Centres</p>
        <!-- <div class="is-small"> -->
        <ul>
          <li>
            <a href="https://cns.hkbu.edu.hk/bhs.htm" target="_blank">
              The Beijing-Hong Kong-Singapore Joint Centre for Nonlinear and
              Complex Systems</a>
            (BHS)
          </li>
          <li>
            <a href="http://www.comp.hkbu.edu.hk/%7Ectr/" target="_blank">Centre for Health Informatics</a>
            (CTTR)
          </li>
          <li>
            <a href="http://www.sci.hkbu.edu.hk/hpccc/" target="_blank">
              High Performance Cluster Computing Centre</a>
            (HPCCC)
          </li>
          <li>
            <a href="http://www.math.hkbu.edu.hk/cmiv/" target="_blank">
              Centre for Mathematical Imaging and Vision</a>
            (CMIV)
          </li>
          <li>
            <a href="http://cns.hkbu.edu.hk/" target="_blank">
              Centre for Nonlinear Studies</a>
            (CNS)
          </li>
          <li>
            <a href="http://www.math.hkbu.edu.hk/JRIAM/" target="_blank">
              Peking University - Hong Kong Baptist University Joint Research
              Institute for Applied Mathematics</a>
            (JRIAM)
          </li>
          <li>
            <a href="http://physics.hkbu.edu.hk/centersysbio/home.htm" target="_blank">
              Centre for Quantitative Systems Biology</a>
            (CQSB)
          </li>
          <li>
            <a href="http://www.math.hkbu.edu.hk/srcc/" target="_blank">
              Statistics Research and Consultancy Centre</a>
            (SRCC)
          </li>
          <li>
            <a href="http://www.sci.hkbu.edu.hk/main/index.php?class=index&amp;action=i&amp;url=ec/research_centre_rcuc_right.html"
              target="_blank">
              Research Centre for Ubiquitous Computing</a>
            (RCUC)
          </li>
        </ul>
        <!-- </div> -->
      </article>
    </div>
    <div class="tile is-parent is-vertical is-4">
      <article class="tile is-child notification is-primary is-light">
        <p class="subtitle">Useful Links</p>
        <ul>
          <li>
            <a href="https://www.sci.hkbu.edu.hk/eng" target="_blank">
              Faculty of Science, Hong Kong Baptist University</a>
          </li>
          <li>
            <a href="http://ioc.hkbu.edu.hk/index.php?lang=en&destHREF=if_Introduction.html" target="_blank">
              Institute of Creativity</a>
          </li>
        </ul>
      </article>
      <article class="tile is-child notification is-warning is-light">
        <p class="subtitle">Policies &amp; Procedures</p>
        <!-- <div class="content is-small has-text-grey-light"> -->
        <ul>
          <li>
            <router-link to="/privacy-policy">Privacy Policy</router-link>
          </li>
        </ul>
        <!-- </div> -->
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkTiles",
  // props: {
  //   msg: String
  // }
};
</script>