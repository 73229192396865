<template>
  <SiteNav />
  <router-view />
  <br />
  <SiteFooter year="2023" />
</template>

<script>
import SiteNav from "@/components/SiteNav.vue";
import SiteFooter from "@/components/SiteFooter.vue";

export default {
  name: "App",
  components: {
    SiteNav,
    SiteFooter,
  },
  mounted: function () {
    let fontawesome = document.createElement("link");
    fontawesome.setAttribute("rel", "stylesheet");
    fontawesome.setAttribute("href", "https://use.fontawesome.com/releases/v5.11.2/css/all.css");
    let head = document.getElementsByTagName("head");
    head[0].appendChild(fontawesome);
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
